import React from "react"
import { graphql } from "gatsby"
import { Typography } from "@material-ui/core"

import { CategoryQuery } from "../../types/graphql-types"

import Layout from "../components/Layout"
import Posts from "../components/Posts"
import SEO from "../components/SEO"

type Props = {
  data: CategoryQuery
  pageContext: {
    currentPage: number
    limit: number
    pageCount: number
    skip: number
  }
}

const CategoryTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const categoryName = data.allMicrocmsPosts.edges[0].node.category?.name || ""
  const categorySlug = data.allMicrocmsPosts.edges[0].node.category?.slug || ""
  const breadcrumbs = [{ name: "ホーム", url: "/" }, { name: categoryName }]

  return (
    <>
      <SEO title={categoryName} />
      <Layout breadcrumbs={breadcrumbs}>
        <Typography component="h2" gutterBottom variant="h5">
          「{categoryName}」の記事一覧
        </Typography>

        <Posts posts={data} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query Category($categorySlug: String!, $limit: Int!, $skip: Int!) {
    allMicrocmsPosts(
      filter: { category: { slug: { eq: $categorySlug } } }
      limit: $limit
      skip: $skip
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          category {
            id
            name
            slug
          }
          content {
            product {
              eyecatch {
                url
              }
              feature
              name
              url
            }
          }
          eyecatch {
            url
          }
          id
          postsId
          publishedAt(formatString: "YYYY/MM/DD")
          slug
          title
        }
      }
    }
  }
`

export default CategoryTemplate
